<template>
  <section>
    <v-skeleton-loader
      v-if="isLoading"
      class="mx-auto"
      type="card"
    />
    <section-error
      v-else-if="isError"
      @fetchData="$emit('fetchData')"
    />
    <v-card v-else outlined>
      <v-card-actions class="grey lighten-5">
        <span class="font-weight-bold ml-2">
          {{$route.query.groupTitle}}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="item.pickupDate"
          icon
          @click="showShipmentExpand = !showShipmentExpand"
        >
          <v-icon>{{ showShipmentExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition v-if="item.pickupDate">
        <div v-show="showShipmentExpand">
          <v-divider></v-divider>
          <v-card-text>
            <span class="grey--text">
              {{$_strings.order.DELIVERY_DATE}} : {{dateFormat(item.pickupDate)}}
            </span>
            <v-card class="mt-2">
              <v-card-text>
                <v-row>
                  <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6">
                    <label class="mb-0 mt-2 grey--text">{{item.label}}</label>
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      class="caption"
                      :value="item.value"
                      disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </section>
</template>

<script>
import { dateFormat } from '@/helper/commonHelpers';
import SectionError from './SectionError.vue';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    SectionError,
  },
  data() {
    return {
      showShipmentExpand: true,
    };
  },
  computed: {
    items() {
      return [
        {
          label: this.$_strings.order.ORIGIN,
          value: this.item.originLocationName,
        },
        {
          label: this.$_strings.order.DESTINATION,
          value: this.item.destinationLocationName,
        },
        {
          label: this.$_strings.order.TARIF_INFO,
          value: this.item.serviceType,
        },
        {
          label: this.$_strings.order.SHIPPER,
          value: this.item.shipperAbbreviation || this.item.abbreviation,
        },
      ];
    },
  },
  methods: {
    dateFormat,
  },
};
</script>
